import * as assetData from "./assetData";
import "./App.css";
import { useState } from "react";
import { BrowserRouter as Router, Routes, Route,Navigate  } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HomePage from "./HomePage";
import TermsAndConditions from "./TermsAndConditions";
import MenuItem from './MenuNavItem';


function App() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <Router>
      <div>
      <Helmet>
        <title>Celleste Fashion Group</title>
        <meta name="description" content="Celleste Fashion Group, a dynamic, innovative fashion and hospitality company, stands at the forefront of promoting the artistry of fashion while simultaneously contributing to the growth of the tourism industry in our vibrant nation" />
      </Helmet>
      {/* Your component's content */}
    </div>
      {/* <div className="mx-auto  flex-row justify-between py-3 px-6 bg-primary lg:flex md:hidden sm:hidden">
        <div className="">
          <a href="mailto:info@cellestegroup.com" className="text-white">
            info@cellestegroup.com
          </a>
        </div>
        <div className="flex flex-row">
          <a
            href="https://www.facebook.com/profile.php?id=61555821396328"
            target="_blank" rel="noreferrer" 
          >
            <img src={assetData.facebook} alt="" className="w-[40%]" />
          </a>

          <a href="#instagram">
            <img src={assetData.instagram} alt="" className="w-[40%]" />
          </a>
        </div>
        <div className="flex flex-row items-center justify-center">
        <img src={assetData.whatsapp} alt="" className="mr-2"/> 
          <a href="tel:91xxxxxxxx" className="text-white">
           +91 xxxx-xxx-xxx
          </a>
        </div>
      </div> */}
      <div className="mx-auto flex flex-row items-center justify-between py-3 px-6 bg-white shadow-primary shadow">
        <div className="">
          <a href="/">
            <img src={assetData.logo} alt="" className="w-[35%]" />
          </a>
        </div>
 
        <div className="desktop-menu flex flex-row items-center hidden md:flex">
        <MenuItem  href="/">Home</MenuItem>
        <MenuItem href="#about" >About</MenuItem>
        <MenuItem href="#registration">Registration</MenuItem>
        <MenuItem href="#gallery" >Gallery</MenuItem>
        <MenuItem href="#faq" >FAQ</MenuItem>
        <MenuItem href="#contact" >Contact</MenuItem>
        <MenuItem href="https://wa.me/+917306779860" ><div className="flex flex-row items-center"><img src={assetData.whatsapp} alt="" className="mr-2"/>  +917306779860</div>  </MenuItem>
      </div>

      {/* Off-Canvas Menu */}
      <div className="mobile-menu md:hidden">
        {/* Toggle Button */}
        <button id="menu-toggle" className="block md:hidden" onClick={toggleMenu}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
        {/* Menu Items */}
        <div id="mobile-menu-items" className={`py-2 px-4 text-gray-800 font-semibold ${isMenuOpen ? 'block' : 'hidden'}`}>
          <a href="/" className="block py-2 px-4 text-gray-800 font-semibold">Home</a>
          <a href="#about" className="block py-2 px-4 text-gray-800 font-semibold">About</a>
          <a href="#registration" className="block py-2 px-4 text-gray-800 font-semibold">Registration</a>
          <a href="#gallery" className="block py-2 px-4 text-gray-800 font-semibold">Gallery</a>
          <a href="#faq" className="block py-2 px-4 text-gray-800 font-semibold">FAQ</a>
          <a href="#contact" className="block py-2 px-4 text-gray-800 font-semibold">Contact</a>
        </div>
      </div>
        {/* <div><a href="" className='text-black'>+91 xxxx-xxx-xxx</a></div> */}
      </div>
      <Routes>
      <Route exact path="" element={<HomePage />}  />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />}  />
      <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      
      <div className="mx-auto flex flex-row py-10 justify-center mt-10 bg-nuetral">
        <div className="w-10/12">
          <div className="flex lg:flex-row sm:flex-col">
            <div className="lg:w-3/12 sm:w-full">
              <img src={assetData.logo} alt="" className="w-[25%]" />
              <p className="mt-1 text-secondary text-[14px] leading-6">
                Celleste Fashion Group, a dynamic, innovative fashion and
                hospitality company, stands at the forefront of promoting the
                artistry of fashion while simultaneously contributing to the
                growth of the tourism industry in our vibrant nation.{" "}
              </p>
            </div>
            <div className="lg:w-2/12 sm:w-full lg:px-6">
              <h4 className="font-bold uppercase text-black text-[20px]">
                Quick Links
              </h4>
              <div className="flex flex-col">
              <a
            href="/"
            className="uppercase text-[14px] font-bold mx-3 text-primary transition-all hover:text-black mb-2"
          >
            Home
          </a>
          <a
            href="#about"
            className="uppercase text-[14px] font-bold mx-3 text-primary transition-all hover:text-black mb-2"
          >
            About
          </a>
          <a
            href="#registration"
            className="uppercase text-[14px] font-bold mx-3 text-primary transition-all hover:text-black mb-2"
          >
            Registration
          </a>
        
          <a
            href="#gallery"
            className="uppercase text-[14px] font-bold mx-3 text-primary transition-all hover:text-black mb-2"
          >
            Gallery
          </a>
          <a
            href="#faq"
            className="uppercase text-[14px] font-bold mx-3 text-primary transition-all hover:text-black mb-2"
          >
            FAQ
          </a>
          <a
            href="#contact"
            className="uppercase text-[14px] font-bold mx-3 text-primary transition-all hover:text-black mb-2"
          >
            Contact
          </a>
              </div>
            </div>
            <div className="sm:w-full lg:w-5/12 lg:px-6">
              <h4 className="font-bold uppercase text-black text-[20px]">
                Address
              </h4>
              <p>
                781H + 5M4 - M02 Deira House - Abu Baker Al Siddique Rd - Dubai
                - United Arab Emirates
              </p>
              <p className="flex flex-row"><img src={assetData.whatsapp} alt="" className="mr-2"/><a href="https://wa.me/7306779860">7306779860 - Ms. Zia (Fashion Stylist)</a>  </p>
              <p className="flex flex-row"><img src={assetData.phone} alt="" className="mr-2"/><a href="tel:9137634463">9137634463 - Ms.Zarni (Events Cordinator)</a></p>
              <p className="flex flex-row"><img src={assetData.phone} alt="" className="mr-2"/><a href="tel:+919582745903">+919582745903 - Ms.Shalu Singh (Fashion Cordinator)</a></p>
              <p className="flex flex-row"><img src={assetData.email} alt="" className="mr-2"/> <a href="mailto:audition@cellestefashiongroup.com">audition@cellestefashiongroup.com</a></p>
              <p className="flex flex-row"><img src={assetData.email} alt="" className="mr-2"/> <a href="mailto:cellestefashiongroup@gmail.com">cellestefashiongroup@gmail.com</a></p>
            </div>
            <div className="lg:w-3/12 sm:w-full lg:px-6">
              <h4 className="font-bold uppercase text-black text-[20px]">
                Get in Touch
              </h4>
              <div className="flex flex-row">
                <a href="https://www.facebook.com/profile.php?id=61556013404486">
                  <img src={assetData.facebook} alt="" className="w-[40%]" />
                </a>

                <a href="https://www.instagram.com/cellestefashiongroup/">
                  <img src={assetData.instagram} alt="" className="w-[40%]" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto flex flex-row justify-center">
        <div className="w-10/12 py-4 text-center">
          <p className="text-[14px] text-primary mb-0">All rights reserved. Celleste Fashion Group</p>
        </div>
      </div>
      </Router>
  );
}

export default App;
