import React from 'react';
import './App.css';

const TermsAndConditions = () => {
  return (
    <>
    
    <div className=" mx-auto p-6 bg-white  rounded-lg terms_and_conditions">
      <h2 className="text-primary lg:text-[40px] sm:text-[25px] font-bold leading-6 uppercase mb-10 text-center">Terms & Conditions</h2>
      <ul className="list-disc pl-6 mb-6">
        <li>The applicant must agree to abide by all rules, as amended from time to time by the Organizer in its sole discretion.</li>
        <li>The applicant should be between 18 – 35 years.</li>
        <li>The applicant must produce the following ID proof - Aadhar Card or Voters ID or Driving License.</li>
        <li>The applicant must agree to pay the registration fee of Rs. 3150/- (early bird offer) / Rs.5,250 (Including Govt Service Tax) - Non-Refundable.</li>
        <li>The applicant should be a natural-born female.</li>
        <li>The Organizers are not responsible for any delays or non-receipt of applications on any account and any reason whatsoever.</li>
        <li>The applicant will have to participate in a disciplined and diligent manner throughout as per schedule for auditions and screening.</li>
        <li>The decision of the judges shall be final in all cases and the applicant does not in any capacity question the same on any platform and no correspondence shall be entertained in this regard.</li>
        <li>The applicant cannot disclose any information or record or discuss the screening process or judges' comments with any third party.</li>
        <li>The applicant cannot get in touch with any judge during the judging process.</li>
        <li>The applicant shall be in good health, sound mind, and have a good moral character to be eligible to participate in the contest.</li>
        <li>The applicant should not have any cases (criminal or civil) registered against her.</li>
        <li>The schedule of the events and qualification rounds are subject to change at the discretion of the Organizer.</li>
      </ul>
      <p className="text-gray-700 mb-4">For further information/clarifications, kindly call the helpline numbers between 09:00 AM to 10:30 PM. Contact: +91 7306779860 or kindly write to us on cellestefashiongroup@gmail.com</p>
    </div>
    </>
  );
};

export default TermsAndConditions;
