import React from 'react';

const smoothScrollTo = (target) => {
  const element = document.querySelector(target);
  if (element) {
    window.scrollTo({
      behavior: 'smooth',
      top: element.offsetTop,
    });
  }
};

const MenuNavItem = ({ href, children }) => {
  const handleClick = (e) => {
    e.preventDefault();
    smoothScrollTo(href);
  };

  return (
    <a href={href} onClick={handleClick} className="uppercase text-[14px] font-bold mx-3 text-primary transition-all hover:text-black">
      {children}
    </a>
  );
};

export default MenuNavItem;
