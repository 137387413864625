import React from 'react'

import { Autoplay, Pagination, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import docFile from "./assets/doc/Application-Form.docx";
import { Link } from 'react-router-dom';
import * as assetData from "./assetData";
import "./App.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
// import required modules

function HomePage() {
    const pagination = {
        clickable: true,
      };
      const handleDownload = () => {
        window.open(docFile);
      };
  return (
    <>
          <div className="mx-auto w-full bannerSlider">
        <Swiper
          pagination={pagination}
          effect={"fade"}
          modules={[Pagination, Autoplay, EffectFade]}
          className="mySwiper"
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <img
              src={assetData.bannerOne}
              alt=""
              className="max-h-[650px] w-full object-cover sm:min-h-[260px] lg:min-h-max"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={assetData.aboutBanner}
              alt=""
              className="max-h-[650px] w-full object-cover sm:min-h-[260px] lg:min-h-max"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={assetData.bannerThree}
              alt=""
              className="max-h-[650px] w-full object-cover sm:min-h-[260px] lg:min-h-max"
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="mx-auto flex flex-row items-center justify-center w-full bg-black py-8 mb-10">
        <div className="w-10/12 text-center">
          <h1 className="text-primary lg:text-[40px] sm:text-[25px] font-bold leading-6 uppercase">
            Miss India International Tourism Pageant
            <span className="text-[#d6d6d6]"></span>
          </h1>
          <h4 className="text-[#d6d6d6] lg:text-[25px] sm-text-[20px] font-bold leading-6">
            2024 Registration Open
          </h4>
          <a href='#registration' className="bg-nuetral py-3 px-6 rounded-md uppercase font-bold text-[14px] mt-3">
            Apply Now
          </a>
        </div>
      </div>
      <div className="mx-auto flex flex-row justify-center py-10" id="about">
        <div className="w-10/12">
          <div className="flex lg:flex-row sm:flex-col">
            <div className="lg:w-5/12 sm:w-full lg:pr-10">
              <div>
                <h3 className="text-primary text-[40px] font-bold leading-6 uppercase">
                  About Us
                </h3>
                <p className="text-paragraph leading-8">
                  Celleste Fashion Group, a dynamic, innovative fashion and
                  hospitality company, stands at the forefront of promoting the
                  artistry of fashion while simultaneously contributing to the
                  growth of the tourism industry in our vibrant nation. With a
                  commitment to nurturing talent and fostering creativity,
                  Celleste Fashion Group organizes spectacular fashion pageants
                  that serve as a platform for aspiring models to showcase their
                  unique style and grace. Beyond the runway, our events are
                  strategically designed to highlight the rich cultural tapestry
                  of our country, enticing both domestic and international
                  audiences to explore the diverse landscapes and experiences
                  that make our nation extraordinary. Celleste Fashion Group
                  believes in the transformative power of fashion to not only
                  shape the modeling industry but also to elevate tourism,
                  making a significant impact on the global stage. Through our
                  endeavors, we aim to create a harmonious blend of fashion and
                  hospitality, leaving an indelible mark on the world of style
                  and the allure of our nation.
                </p>
              </div>
            </div>
            <div className="lg:w-7/12 sm:w-full lg:pl-10">
              <img src={assetData.bannerTwo} alt="" className="w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto flex flex-row justify-center py-10" id="registration">
        <div className="w-10/12">
          <h3 className="text-primary text-[40px] font-bold leading-6 uppercase text-center">
            REGISTRATION
          </h3>
          <p className="text-paragraph leading-9 text-center">
            The Miss India International Tourism Pageant, an emblem of elegance
            and cultural enrichment, is an annual celebration that transcends
            conventional beauty standards. With a unique and visionary theme
            centered around promoting tourism, this pageant is not merely a
            showcase of external beauty but a journey to discover individuals
            who personify grace, intellect, and a passion for fostering global
            connections. As contestants grace the stage, they not only mesmerize
            with their poise but also showcase a profound understanding of the
            diverse landscapes and cultures that define our nation. This pageant
            serves as a transformative platform, aiming not just to crown a
            winner but to mold models into supermodels with a global outlook. By
            integrating the tourism concept into the fabric of the competition,
            Miss India International Tourism Pageant aims to create ambassadors
            who not only epitomize beauty but also possess the ability to
            represent the rich tapestry of India to the world. Through this
            innovative approach, the pageant becomes a beacon for fostering
            cross-cultural understanding and promoting the scenic wonders that
            make India an unparalleled destination on the world stage.
          </p>
        </div>
      </div>
      <div className="mx-auto flex flex-row justify-center pt-10">
        <div className="w-full">
          <div className="grid grid-cols-4">
            <div className="overflow-hidden">
              <img
                src={assetData.regTen}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.regFive}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.regTwo}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.regThree}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px] object-left"
              />
            </div>
            
          </div>
        </div>
      </div>
      <div className="mx-auto flex flex-row justify-center py-10 bg-black pt-16 mb-10" id="events">
        <div className="w-10/12">
          <h3 className="text-nuetral lg:text-[40px] sm:text-[25px] font-bold leading-6 uppercase text-center">
            Pageant Schedule
          </h3>
          <div className="flex lg:flex-row sm:flex-col">
            <div className="lg:w-4/12 sm:w-full">
              <div className="lg:p-10">
                <div className="flex flex-row items-center justify-start bg-nuetral p-5 rounded-md mb-5">
                  <div className="w-[10%] mr-10">
                    <img src={assetData.star} alt="" />
                  </div>
                  <div>
                    <h5 className="text-[#ed8a19] lg:text-[25px] sm:text-[20px] font-bold">
                      Mega Show
                    </h5>
                    <p className="text-[18px] font-bold mb-0">05 August 2024</p>
                    <p className="text-[18px] font-bold">
                      Venue: The Lalit New Delhi
                    </p>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-start bg-nuetral p-5 rounded-md mb-5">
                  <div className="w-[10%] mr-10">
                    <img src={assetData.star} alt="" />
                  </div>
                  <div>
                    <h5 className="text-[#ed8a19] lg:text-[25px] sm:text-[20px] font-bold">
                    Registration
                    </h5>
                    <p className="text-[18px] font-bold mb-0">01 Jan 2024 - 02 June 2024</p>
                    
                  </div>
                </div>
                <div className="flex flex-row items-center justify-start bg-nuetral p-5 rounded-md mb-5">
                  <div className="w-[10%] mr-10">
                    <img src={assetData.star} alt="" />
                  </div>
                  <div>
                    <h5 className="text-[#ed8a19] lg:text-[25px] sm:text-[20px] font-bold">
                    Grooming & Study Class:
                    </h5>
                    <p className="text-[18px] font-bold mb-0">05 - 10 July 2024 - Delhi</p>
                    <p className="text-[18px] font-bold">
                    Venue: The Lalit New Delhi
                    </p>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="lg:w-8/12 sm:w-full">
              <div className="mt-5">
                <div class="mb-6 mt-10">
                  <h3 class="text-[30px] text-center font-bold  text-primary uppercase bg-white p-3 mb-0">
                    Audition
                  </h3>
                  <table class="w-full mb-4 bg-nuetral ">
                    <thead>
                      <tr class="bg-primary text-white ">
                        <th class="py-2 px-4">Date</th>
                        <th class="py-2 px-4">Location</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b border-[#ccc]">
                        <td class="py-2 px-4 text-center font-bold text-[20px]">
                          06 - 08 June 2024
                        </td>
                        <td class="py-2 px-4 text-center font-bold text-[20px]">
                          Delhi
                        </td>
                      </tr>
                      <tr className="border-b border-[#ccc]">
                        <td class="py-2 px-4 text-center font-bold text-[20px]">
                          09 - 10 June 2024
                        </td>
                        <td class="py-2 px-4 text-center font-bold text-[20px]">
                          Kolkata
                        </td>
                      </tr>
                      <tr className="border-b border-[#ccc]">
                        <td class="py-2 px-4 text-center font-bold text-[20px]">
                          11 - 12 June 2024
                        </td>
                        <td class="py-2 px-4 text-center font-bold text-[20px]">
                          Hyderabad
                        </td>
                      </tr>
                      <tr className="border-b border-[#ccc]">
                        <td class="py-2 px-4 text-center font-bold text-[20px]">
                          13 - 14 June 2024
                        </td>
                        <td class="py-2 px-4 text-center font-bold text-[20px]">
                          Bangalore
                        </td>
                      </tr>
                      <tr className="border-b border-[#ccc]">
                        <td class="py-2 px-4 text-center font-bold text-[20px]">
                          14 - 15 June 2024
                        </td>
                        <td class="py-2 px-4 text-center font-bold text-[20px]">
                          Chennai
                        </td>
                      </tr>
                      <tr className="border-b border-[#ccc]">
                        <td class="py-2 px-4 text-center font-bold text-[20px]">
                          21 - 23 June 2024
                        </td>
                        <td class="py-2 px-4 text-center font-bold text-[20px]">
                          Mumbai
                        </td>
                      </tr>
                      <tr className="border-b border-[#ccc]">
                        <td class="py-2 px-4 text-center font-bold text-[20px]">
                          24 - 27 June 2024
                        </td>
                        <td class="py-2 px-4 text-center font-bold text-[20px]">
                          Dubai
                        </td>
                      </tr>
                      <tr className="border-b border-[#ccc]">
                        <td class="py-2 px-4 text-center font-bold text-[20px]">
                          28 - 29 June 2024
                        </td>
                        <td class="py-2 px-4 text-center font-bold text-[20px]">
                          Abu Dhabi
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto flex flex-row justify-center py-10">
        <div className="w-10/12">
          <h1 className="text-primary lg:text-[40px] sm:text-[25px] font-bold leading-6 uppercase mb-10">
            Eligibility Criteria
          </h1>
          <div className="flex lg:flex-row sm:flex-col">
            <div className="lg:w-full sm:w-full">
              <div className='regFeatureImage p-8 relative before:absolute before:content-[""] before:left-0 before:top-0 before:bg-black before:opacity-50 before:w-full before:h-full '>
                <div className="relative grid grid-cols-3 text-center ">
                  <div>
                  <h5 className="text-[20px] font-bold text-[#f5c715]">
                    Early Bird Offer - <span>Till April 2024</span>{" "}
                  </h5>
                  <p className="text-white text-[16px] ">
                    Registration Fee : Rs. 3150 (Inclusive of Taxes)
                  </p>
                  </div>
                  <div>
                  <h5 className="text-[20px] font-bold text-[#f5c715]">
                    From 01 May 2024
                  </h5>
                  <p className="text-white text-[16px] ">
                    Registration Fee:Rs. 5,250 (Inclusive of Taxes)
                  </p>
                  </div>
                  <div>
                  <h5 className="text-[20px] font-bold text-[#f5c715]">Age</h5>
                  <p className="text-white text-[16px] ">18 to 35 years</p>
                  </div>
                  <div>
                  <h5 className="text-[20px] font-bold text-[#f5c715]">
                    Marital Status
                  </h5>
                  <p className="text-white text-[16px] ">Single / Married</p>
                  </div>
                  <div>
                  <h5 className="text-[20px] font-bold text-[#f5c715]">
                    Nationality
                  </h5>
                  <p className="text-white text-[16px] ">Indian</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="lg:w-8/12 sm:w-full text-center pl-8 lg:mt-0 sm:mt-5 registratioBanners">
              <div className="relative flex flex-col justify-start items-start">
                <h4 className="text-primary text-[25px] font-bold leading-6 uppercase text-center">
                  SAMPLE FORM
                </h4>
                <img src={assetData.sampleForm} alt="" />
              </div>
            </div> */}
          </div>
          <div className="w-full text-center mt-6 block">
            <button
              onClick={handleDownload}
              className="bg-nuetral py-3 px-6 rounded-md uppercase font-bold lg:text-[14px] mt-3 cursor-pointer sm:text-[12px]"
            >
              Click here to download application form
            </button><br/>
            <Link to="/terms-and-conditions" className='mt-6 block text-red-500'>Accept Terms and Conditions</Link>
          </div>
        </div>
      </div>

      <div className="mx-auto flex flex-row justify-center pt-10" id="gallery">
        <div className="w-full">
          <h1 className="text-primary text-[40px] font-bold leading-6 uppercase mb-10 text-center">
            Gallery
          </h1>
          <div className="grid grid-cols-5">
            <div className="overflow-hidden">
              <img
                src={assetData.gaOne}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.gaTwo}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.gaThree}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.gaFour}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.gaFive}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.gaSix}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.gaSeven}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.gaEight}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.gaNine}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.gaTen}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.gaEleven}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.gaTwelve}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.gaThirteen}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.gaFourteen}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
            <div className="overflow-hidden">
              <img
                src={assetData.gaFifteen}
                alt=""
                className="h-full object-cover transition-all scale-100 hover:scale-110 md:max-h-max sm:max-h-[150px]"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto flex flex-row justify-center pt-10" id="faq">
        <div className="w-10/12">
          <h1 className="text-primary text-[40px] font-bold leading-6 uppercase mb-10 ">
            FAQ
          </h1>
          <div className="w-full">
            <div class="mx-auto">
              <div class="accordion">
                <div class="accordion-item">
                  <input
                    type="checkbox"
                    id="faq-item-1"
                    class="accordion-toggle"
                  />
                  <label for="faq-item-1" class="accordion-title font-bold">
                    What is the joining process?
                  </label>
                  <div class="accordion-content">
                    <p className="p-[20px]">
                      Click on the Registration Tab and fill up the details. Or
                      download the application form given in the site and email
                      us. Make the registration fee of INR 3,150/- and mention
                      the UPI transaction id. Our team shall acknowledge and
                      revert with application number.
                    </p>
                  </div>
                </div>

                <div class="accordion-item">
                  <input
                    type="checkbox"
                    id="faq-item-2"
                    class="accordion-toggle"
                  />
                  <label for="faq-item-2" class="accordion-title font-bold">
                    What all payment options available?
                  </label>
                  <div class="accordion-content">
                    <p className="p-[20px]">
                      We have bank account number & Gpay. Upon request we shall
                      share it with you. Our G pay number is 7306779860.
                    </p>
                  </div>
                </div>

                <div class="accordion-item">
                  <input
                    type="checkbox"
                    id="faq-item-3"
                    class="accordion-toggle"
                  />
                  <label for="faq-item-3" class="accordion-title font-bold">
                    How do I know my application is accepted?
                  </label>
                  <div class="accordion-content">
                    <p className="p-[20px]">
                      You will get an email confirmation from our admin.
                    </p>
                  </div>
                </div>

                <div class="accordion-item">
                  <input
                    type="checkbox"
                    id="faq-item-4"
                    class="accordion-toggle"
                  />
                  <label for="faq-item-4" class="accordion-title font-bold">
                    My height is short. Can I apply?
                  </label>
                  <div class="accordion-content">
                    <p className="p-[20px]">
                      We have not put any limitation on height and weight.
                      Beauty is not in physical looks. We are here to guide and
                      make your dreams come true. Be confident and chase your
                      dreams.
                    </p>
                  </div>
                </div>

                <div class="accordion-item">
                  <input
                    type="checkbox"
                    id="faq-item-5"
                    class="accordion-toggle"
                  />
                  <label for="faq-item-5" class="accordion-title font-bold">
                    Audition details
                  </label>
                  <div class="accordion-content">
                    <p className="p-[20px]">
                      You will be called over phone and email with the audition
                      venue, date and time. Walk in and there will be a 1-2-1
                      personal round. Final shortlisting is based on audition
                      call.
                    </p>
                  </div>
                </div>

                <div class="accordion-item">
                  <input
                    type="checkbox"
                    id="faq-item-6"
                    class="accordion-toggle"
                  />
                  <label for="faq-item-6" class="accordion-title font-bold">
                    Still have more questions?
                  </label>
                  <div class="accordion-content">
                    <p className="p-[20px]">
                      Feel free to call or WhatsApp at +91 7306779860.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto flex flex-row py-10 justify-center mt-10" id="contact">
        <div className="w-10/12">
          <h1 className="text-primary text-[40px] font-bold leading-6 uppercase mb-10 text-center">
            Contact US
          </h1>
          <div className="grid lg:grid-cols-4 sm:grid-cols-1 gap-6">
            <div className="p-6 rounde-md bg-[#f1f1f1] shadow-md">
              <h4 className="font-bold text-[25px] uppercase">Dubai</h4>
              <p>
                Address: 781H + 5M4 - M02 Deira House - Abu Baker Al Siddique Rd
                - Dubai - United Arab Emirates
              </p>
            </div>
            <div className="p-6 rounde-md bg-[#f1f1f1] shadow-md">
              <h4 className="font-bold text-[25px] uppercase">New Delhi</h4>
              <p>
              Fire Brigade Lane, Barakhamba, New Delhi, Delhi 110001
              </p>
            </div>
            <div className="p-6 rounde-md bg-[#f1f1f1] shadow-md">
              <h4 className="font-bold text-[25px] uppercase">Bangalore</h4>
              <p>
              1/4 Swami Vivekananda Road, Mahatma Gandhi Rd, Halasuru, Bengaluru, Karnataka 560008
              </p>
            </div>
            <div className="p-6 rounde-md bg-[#f1f1f1] shadow-md">
              <h4 className="font-bold text-[25px] uppercase">Australia</h4>
              <p>
              439 St Kilda Road, Melbourne, Victoria, 3004
              </p>
            </div>
          </div>
        </div>
      </div>
    
    </>
  )
}

export default HomePage
